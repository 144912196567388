<template>

    <!-- record modal starts here -->
    <transition name="modal">
        <div class="modalBg modal-active" v-if="recordModal.isOpen">
            <div class="modalLayers" @click="recordModal.isOpen = false"></div>
            <div class="modalBody">

                <div class="addProductTitle">
                    <h3 v-if="recordType == 'cart'">
                        {{ recordModal.parent.name }}'s Cart
                    </h3>
                    <h3 v-if="recordType == 'usage'">
                        {{ recordModal.parent.name }}'s Usage Details
                    </h3>
                    <h3 v-if="recordType == 'csv_import_request'">
                        Csv Import Requests
                    </h3>
                </div>

                <div class="searchProductForm" style="margin-bottom:0px !important" v-if="recordType == 'cart' || recordType == 'usage'">
                    <input
                        type="search"
                        name="search_item"
                        id="search_item"
                        :placeholder="recordType == 'cart' ? 'Search Cart Item' : recordType == 'usage' ? 'Search Usage Details' : '--'"
                        v-model="recordModal.searchedKeyword"
                        @input="handleRecordLoading('search', 1)"
                        style="width: 100%;"
                    />
                </div>

                <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px; overflow: auto;">
                    <div id="London" class="tabcontent">
                        <div class="content-table">

                            <table class="table" v-if="recordType == 'cart'">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Name">
                                                        Name
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Type">
                                                        Type
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Quantity">
                                                        Quantity
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Added At">
                                                        Added At
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="recordModal.isLoading">
                                    <tr v-for="i in 5" :key="`cart_loader_${i}`">
                                    
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="editFieldTitle customerNameField">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="120"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="80"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="120"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="recordModal.record_items.length == 0">
                                        <td colspan="6" style="text-align:center" v-if="recordModal.searchedKeyword">
                                            <p class="mb-0">
                                                No cart items found matching '{{recordModal.searchedKeyword}}'
                                            </p>
                                        </td>
                                        <td colspan="6" style="text-align:center" v-else><p class="mb-0">No Cart Items Available</p></td>
                                    </tr>
                                    <tr v-for="(item,index) in recordModal.record_items" :key="`record_item_${index}`">

                                        <!-- image block starts here -->
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div style="width: 100%;">
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmDataTableImgBox" v-if="item.product">
                                                            <img 
                                                                :src="item.product.image ? base_image_url + settings.store_id + '/product/' + item.product.image : '/images/no_image.png'" 
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                        <div class="ctmDataTableImgBox" v-else-if="item.product_bundle">
                                                            <img 
                                                                :src="item.product_bundle.image ? base_image_url + settings.store_id + '/product-bundle/' + item.product_bundle.image : '/images/no_image.png'" 
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- image block ends here -->

                                        <!-- name block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{ 
                                                        item.product ? 
                                                        item.product.product_type == 'variable' ? 
                                                        item.product.name +" | "+ item.variant.name : item.product.name : 
                                                        item.product_bundle ? item.product_bundle.name : '--'
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- name block ends here -->

                                        <!-- type block starts here -->
                                        <td>
                                            <div class="selectCategoryBoxTable">
                                                <p>
                                                    {{ 
                                                        item.product ? item.product.product_type == 'variable' ? `Product | Variable` : `Product | Simple` : 
                                                        item.product_bundle ? 'Product Bundle' : '--'
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- type block ends here -->

                                        <!-- quantity block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{ item.qty }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- quantity block ends here -->

                                        <!-- added at block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{
                                                        item.created_at
                                                        ? new Date(item.created_at).toLocaleString("en-US", {
                                                            year: "numeric",
                                                            }) +
                                                            "-" +
                                                            new Date(item.created_at).toLocaleString("en-US", {
                                                            month: "numeric",
                                                            }) +
                                                            "-" +
                                                            new Date(item.created_at).toLocaleString("en-US", {
                                                            day: "numeric",
                                                            }) +
                                                            " | " +
                                                            new Date(item.created_at).toLocaleTimeString("en-US")
                                                        : "--"
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- added at block ends here -->

                                    </tr>
                                </tbody>
                            </table>

                            <table class="table" v-if="recordType == 'usage'">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Customer Name">Customer Name</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Customer Email">Customer Email</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Order No.">Order No.</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Discounted Amount">Discounted Amount</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Used At">Used At</p>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="recordModal.isLoading">
                                    <tr v-for="i in 5" :key="`usage_loader_${i}`">
                                    
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="editFieldTitle customerNameField">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="120"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="80"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="80"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                                <tbody v-else>

                                    <tr v-if="recordModal.record_items.length == 0">
                                        <td colspan="6" style="text-align:center" v-if="recordModal.searchedKeyword">
                                            <p class="mb-0">
                                                No usage details found matching '{{recordModal.searchedKeyword}}'
                                            </p>
                                        </td>
                                        <td colspan="6" style="text-align:center" v-else><p class="mb-0">No Usage Details Available</p></td>
                                    </tr>
                                    <tr v-for="(item,index) in recordModal.record_items" :key="`record_item_${index}`">

                                        <!-- customer name block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{ 
                                                        item.user ? 
                                                        item.user.first_name +" "+ item.user.last_name : "--"
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- customer name block ends here -->

                                        <!-- customer email block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{ 
                                                        item.user ? 
                                                        item.user.email : "--"
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- customer email block ends here -->

                                        <!-- order no. email block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    <b>
                                                        {{ 
                                                            item.order ? 
                                                            item.order.order_no : "--"
                                                        }}
                                                    </b>
                                                    
                                                </p>
                                            </div>
                                        </td>
                                        <!-- order no. email block ends here -->

                                        <!-- discounted amount block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    <b>
                                                        {{ 
                                                            settings.currency_symbol + " " + Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(item.discounted_amount )
                                                        }}
                                                    </b>
                                                </p>
                                            </div>
                                        </td>
                                        <!-- discounted amount block ends here -->

                                        <!-- used at block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{
                                                        item.created_at
                                                            ? new Date(item.created_at).toLocaleString("en-US", {
                                                                year: "numeric",
                                                            }) +
                                                            "-" +
                                                            new Date(item.created_at).toLocaleString("en-US", {
                                                                month: "numeric",
                                                            }) +
                                                            "-" +
                                                            new Date(item.created_at).toLocaleString("en-US", {
                                                                day: "numeric",
                                                            }) +
                                                            " | " +
                                                            new Date(item.created_at).toLocaleTimeString("en-US")
                                                            : "--"
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- used at block ends here -->

                                    </tr>

                                </tbody>
                            </table>

                            <table class="table" v-if="recordType == 'csv_import_request'">
                                <thead>
                                    <tr>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Platform">Platform</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Total Products">Total Products</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Status">Status</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Requested At">Requested At</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Requested By">Requested By</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="recordModal.isLoading">
                                    <tr v-for="i in 5" :key="`csv_import_requests_loader_${i}`">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="recordModal.record_items.length == 0">
                                        <td colspan="7" style="text-align:center">
                                            <p class="mb-0">
                                                No Csv Import Requests Available
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-for="(item,index) in recordModal.record_items" :key="`record_item_${index}`">

                                        <!-- platform block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ item.platform.charAt(0).toUpperCase() + item.platform.slice(1) }}</p>
                                            </div>
                                        </td>
                                        <!-- platform block ends here -->
                                        
                                        <!-- import data block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <el-tooltip 
                                                    class="box-item" 
                                                    effect="dark" 
                                                    :content="item.import_data ? `${item.import_data}` : '0'" 
                                                    placement="left"
                                                >
                                                    <p data-open="true">
                                                        <b data-open="true">
                                                            {{
                                                                item.import_data ?
                                                                Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(item.import_data) 
                                                                :  0
                                                            }}
                                                        </b>
                                                    </p>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                        <!-- import data block ends here -->

                                        <!-- status data block starts here -->
                                        <td>
                                            <div class="selectCategoryBoxTable">
                                                <p>
                                                    {{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- status data block ends here -->

                                        <!-- requested at block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{
                                                        item ? new Date(item.created_at).toLocaleString("en-US",{year: 'numeric'}) + "-"+
                                                        new Date(item.created_at).toLocaleString("en-US",{month: 'numeric'}) + "-"+
                                                        new Date(item.created_at).toLocaleString("en-US",{day: 'numeric'})
                                                        + ' | '+ new Date(item.created_at).toLocaleTimeString('en-US') : '--' 
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- requested at block ends here -->

                                        <!-- submitted by block starts here -->
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>
                                                    {{
                                                        item.submitted_by ? 
                                                        item.submitted_by.first_name +" "+ item.submitted_by.last_name : '--'
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- submitted by block ends here -->

                                        <!-- request deletion block starts here -->
                                        <td>
                                            <div class="ctmDataTableActionBtn">
                                                <button class="delFunction" @click.prevent="removeCsvImportRequestHandle(item)">
                                                    <i class="fa fa-trash-o"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- request deletion block ends here -->

                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <hr>

                <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-count="recordPagination.total"
                    @current-change="recordPaginateChangeHandle"
                    class="mt-4"
                />

                <hr>

                <div class="modalCloseIcon">
                    <button @click.prevent="recordModal.isOpen = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>

            </div>
        </div>
    </transition>
    <!-- record modal ends here -->

</template>

<script>
import { mapGetters } from 'vuex';
import { MessageBox, Message, Loading } from "element-ui";

export default {
    name: "RecordPreviewModal",
    data(){
        return {

            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
            recordModal:{
                parent:{
                    name: ''
                },
                parentId: '',
                cartId: '',
                record_items: [],
                searchedKeyword: '',
                isOpen: false,
                isLoading: false,
            },
            recordPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            },
            timer: undefined,

        }
    },
    props:{
        moduleName:{
            type: String,
            required: true,
            description: 'module name',
        },
        recordType:{
            type: String,
            required: true,
            description: 'record type',
        },
    },
    components:{
    },
    computed:{
        ...mapGetters({
            settings: "settings_module/settings",
        }),
    },
    watch:{
    },
    methods:{

        openModal(parentRecord){

            if(this.moduleName == 'product'){

                if(this.recordType == 'csv_import_request'){

                    this.recordModal = {
                        parent: {
                            name: "",
                        },
                        parentId: "",
                        cartId: "",
                        record_items: [],
                        searchedKeyword: '',
                        isOpen: true,
                        isLoading: false,
                    }

                }
                            
            }else if(this.moduleName == 'customer'){

                if(this.recordType == 'cart'){

                    this.recordModal = {
                        parent: {
                            name: parentRecord.first_name + " " + parentRecord.last_name
                        },
                        parentId: parentRecord.id,
                        cartId: parentRecord.cart.id,
                        record_items: [],
                        searchedKeyword: '',
                        isOpen: true,
                        isLoading: false,
                    }

                }

            }else if(this.moduleName == 'coupon'){

                if(this.recordType == 'usage'){

                    this.recordModal = {
                        parent: {
                            name: parentRecord.coupon_code
                        },
                        parentId: parentRecord.id,
                        cartId: "",
                        record_items: [],
                        searchedKeyword: '',
                        isOpen: true,
                        isLoading: false,
                    }

                }

            }

            this.recordPagination = {
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            }

            this.handleRecordLoading('simple', 1);

        },
        recordPaginateChangeHandle(currentPage, pageSize){

            this.handleRecordLoading('simple', currentPage);

        },
        async handleRecordLoading(operation, page){

            if(operation == 'simple'){

                this.loadRecordData(page, this.recordModal.searchedKeyword);

            }else if(operation == 'search'){

                this.recordModal.isLoading = true;

                clearTimeout(this.timer);

                this.timer = setTimeout(async () => {

                    this.loadRecordData(page, this.recordModal.searchedKeyword);

                }, 300);

            }

        },
        async loadRecordData(page, keyword){

            if(this.moduleName == 'customer' && this.recordType == 'cart'){

                this.recordModal.isLoading = true;
                try{
                    let res = await this.$axios.get('/customer/cart/item/all',{
                        params:{
                            customer_id: this.recordModal.parentId,
                            cart_id: this.recordModal.cartId,
                            search: keyword.trim() ? keyword.trim() : null,
                            offset: 10,
                            page: page,
                        }
                    });
                    if(res.data.status_code == "1791"){

                        this.recordModal.record_items = _.cloneDeep(res.data.cart_items.data);

                        this.recordPagination = {
                            current_page: page,
                            total: res.data.cart_items.last_page,
                            previous_page: 0,
                            last_page: res.data.cart_items.last_page
                        }

                    }
                }catch(error){

                    this.recordModal.isOpen = false;

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.recordModal.isLoading = false;

                }

            }else if(this.moduleName == 'coupon' && this.recordType == 'usage'){

                this.recordModal.isLoading = true;
                try{
                    let res = await this.$axios.get('/coupon/usage/all',{
                        params:{
                            coupon_id: this.recordModal.parentId,
                            search: keyword.trim() ? keyword.trim() : null,
                            offset: 10,
                            page: page,
                        }
                    });
                    if(res.data.status_code == "1075"){

                        this.recordModal.record_items = _.cloneDeep(res.data.usage_details.data);

                        this.recordPagination = {
                            current_page: page,
                            total: res.data.usage_details.last_page,
                            previous_page: 0,
                            last_page: res.data.usage_details.last_page
                        }

                    }
                }catch(error){

                    this.recordModal.isOpen = false;

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.recordModal.isLoading = false;

                }

            }else if(this.moduleName == 'product' && this.recordType == 'csv_import_request'){

                this.recordModal.isLoading = true;
                try{
                    let res = await this.$axios.get('/product/csv-import/all',{
                            params:{
                            store_id: this.settings.store_id,
                            size: 10,
                            page: page,
                            search: keyword && keyword.trim() ? keyword.trim() : null,
                        }
                    });
                    if(res.data.status_code == "1363"){

                        this.recordModal.record_items = _.cloneDeep(res.data.csv_import_requests.data);

                        this.recordPagination = {
                            current_page: page,
                            total: res.data.csv_import_requests.last_page,
                            previous_page: 0,
                            last_page: res.data.csv_import_requests.last_page
                        }

                    }
                }catch(error){

                    this.recordModal.isOpen = false;

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.recordModal.isLoading = false;

                }

            }

        },


        removeCsvImportRequestHandle(listedRequest){

            MessageBox.confirm(
                'Are you sure you want to delete the csv import request?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async () => {

                let loader = Loading.service({
                    text: "The csv import request is being terminated. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.delete(`product/csv-import/remove/${listedRequest.id}`);
                    if(res.data.status_code == "1368"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.handleRecordLoading('simple',1);

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data && error.response.data.error &&
                        error.response.data.error.id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }
                
            }).catch(() => {})

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
}
</script>

<style scoped>

    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }

</style>