<template>
  <div class="right-menu shipping-right">

    <subHeader pageName="CUSTOMERS" />

    <tutorialsAction moduleName="CUSTOMERS"/>

    <div class="db_top">
      <div class="db-container">

        <moduleStats/>

        <!-- search block starts here -->
        <moduleSearch :moduleName="'customer'" :disable="tourIsActive" ref="module_search"/>
        <!-- search block ends here -->

        <!-- data table starts here -->
        <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
          <div class="tableScrollWrap" ref="customersTable">
            <table>
              <thead>
                <tr>
                  <th>
                    <div class="ctmCheckWrap">
                      <label class="ctmTableCheckContainer">
                        <input 
                          type="checkbox"
                          @click="toggleIsCheckedAll()"
                          :checked="checkedItems.length != 0 && checkedItems.length == currentCustomerList.length"
                          :disabled="tourIsActive || this.selectedCustomer.id != ''"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <div class="ctmTableImgWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="ctmCheckWrap">
                      <div class="ctmTableImgWrap">
                        <p data-toggle="tooltip" data-placement="top" title="First Name">First Name</p>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="ctmCheckWrap">
                      <div class="ctmTableImgWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Last Name">Last Name</p>
                      </div>
                    </div>
                  </th>
                  <th class="ctmPr-3" style="min-width: 240px;">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Email">Email</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Phone">Phone</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="City">City</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Total Orders">Total Orders</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Last Purchase">Last Purchase</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Purchase Volume">Purchase Volume</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Cart">Cart</p>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Active/Disable">Active/Disable</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="boxIsLoading">
                <tr v-for="i in 5" :key="i">
                  <td>
                    <div class="ctmCheckWrap">
                      <label class="ctmTableCheckContainer">
                        <vue-skeleton-loader
                          type="rect"
                          :width="17"
                          :height="20"
                          animation="fade"
                        />
                      </label>
                      <div class="editFieldTitle customerNameField">
                        <vue-skeleton-loader
                          type="circle"
                          :width="36"
                          :height="36"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="ctmCheckWrap">
                      <div class="ctmTableImgWrap">
                        <vue-skeleton-loader
                          type="rect"
                          :width="80"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="ctmCheckWrap">
                      <div class="editFieldTitle customerNameField">
                        <vue-skeleton-loader
                          type="rect"
                          :width="80"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td class="mainTableTitle">
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="140"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="100"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="54"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="112"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="120"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="120"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="actionSwitch">
                      <vue-skeleton-loader
                        type="rect"
                        :width="70"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="actionSwitch">
                      <vue-skeleton-loader
                        type="rect"
                        :width="70"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-if="currentCustomerList && currentCustomerList.length == 0">
                  <td colspan="11" style="text-align:center">
                    <p class="mb-0">
                      {{ customerSearchKeyword ? `No customers found matching '${customerSearchKeyword}'` : 'No Customers Available' }}
                    </p>
                  </td>
                </tr>
                <tr v-for="(customer, index) in currentCustomerList" :key="index">
                  
                  <!-- checkbox & image block starts here -->
                  <td @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="ctmCheckWrap" data-open="true">
                      <div style="width: 100%;" data-open="true">
                        <div class="ctmCheckWrap" data-open="true">
                          <label class="ctmTableCheckContainer" data-open="false" @click=" customer.id != -1 ? checkedItemHandle(customer.id) : ''">
                            <input type="checkbox" v-model="checkedItems" :value="customer.id" :disabled="tourIsActive || customer.id == -1" data-open="false">
                            <span class="checkmark" data-open="false"></span>
                          </label>
                          <div class="ctmDataTableImgBox" @click.prevent="previewCustomer(customer, index, 'solid', $event)" data-open="true" v-if="customer.id == -1">
                            <img 
                              :src="customer.addImage ? customer.addImage_src : '/images/customer_noImage.png'" 
                              alt=""
                              v-if="customer.id == -1"
                              data-open="true"
                              @error="handleImageError"
                            >
                            <img
                              :src="generateUrl(customer) ? generateUrl(customer) : '/images/customer_noImage.png'"
                              alt=""
                              data-open="true"
                              @error="handleImageError"
                              v-else
                            >
                          </div>
                          <div class="ctmDataTableImgBox" @click.prevent="previewCustomer(customer, index, 'solid', $event)" data-open="true" v-else>
                            <img 
                              :src="customer.addImage ? customer.addImage_src : '/images/customer_noImage.png'" 
                              alt=""
                              v-if="customer.id == -1"
                              data-open="true"
                              @error="handleImageError"
                            >
                            <img
                              :src="generateUrl(customer) ? generateUrl(customer) : '/images/customer_noImage.png'"
                              alt=""
                              data-open="true"
                              @error="handleImageError"
                              v-else
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- checkbox & image block ends here -->

                  <!-- first name block starts here -->
                  <td @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="editFieldTitle customerNameField" data-open="true" id="table-first-name-field">
                      <input 
                        type="text"
                        placeholder="Write a customer first name"
                        v-model="customer.first_name" 
                        @input="handleFormat('table','f_name',customer)"
                        @focus="customerSelection(customer)" 
                        @blur="handleOTGUpdate(customer,index)"
                        :disabled="tourIsActive"
                        data-open="false"
                      >
                    </div>
                  </td>
                  <!-- first name block ends here -->

                  <!-- last name block starts here -->
                  <td @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="ctmCheckWrap" data-open="true" id="table-last-name-field">
                      <div class="editFieldTitle customerNameField" data-open="true">
                        <input 
                          type="text" 
                          placeholder="Write a customer last name"
                          v-model="customer.last_name" 
                          @input="handleFormat('table','l_name',customer)"
                          @focus="customerSelection(customer)" 
                          @blur="handleOTGUpdate(customer,index)"
                          :disabled="tourIsActive"
                          data-open="false"
                        >
                      </div>
                    </div>
                  </td>
                  <!-- last name block ends here -->

                  <!-- email block starts here -->
                  <td class="mainTableTitle" @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="editFieldTitle" data-open="true" id="table-email-field">
                      <input 
                        type="email" 
                        placeholder="Write a customer email address"
                        v-model="customer.email" 
                        @focus="customerSelection(customer)" 
                        @blur="handleOTGUpdate(customer,index)"
                        :disabled="tourIsActive"
                        data-open="false"
                      >
                    </div>
                  </td>
                  <!-- email block ends here -->

                  <!-- phone no. block starts here -->
                  <td @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="tableBodyCtmTxt" data-open="true">
                      <p data-open="true">{{ customer.mobile_no ? customer.mobile_no : ''}}</p>
                    </div>
                  </td>
                  <!-- phone no. block ends here -->

                  <!-- city block starts here -->
                  <td style="position: relative;" @click.prevent="previewCustomer(customer,index,'solid',$event)">
                    <div class="tableBodyCtmTxt" data-open="true">
                      <p data-open="true">{{ customer.city ? customer.city.name : ''}}</p>
                    </div>
                  </td>
                  <!-- city block ends here -->

                  <!-- total orders block starts here -->
                  <td style="position: relative;" @click.prevent="previewCustomer(customer,index,'solid',$event)">
                    <div class="tableBodyCtmTxt" data-open="true">
                      <p data-open="true">{{ customer.orders_count }}</p>
                    </div>
                  </td>
                  <!-- total orders block ends here -->

                  <!-- last purchased block starts here -->
                  <td style="position: relative;" @click.prevent="previewCustomer(customer,index,'solid',$event)">
                    <div class="tableBodyCtmTxt" data-open="true">
                      <p style="font-size: 12px" data-open="true">
                        {{
                          customer.last_purchase ? new Date(customer.last_purchase.created_at).toLocaleString("en-US",{year: 'numeric'}) + "-"+
                          new Date(customer.last_purchase.created_at).toLocaleString("en-US",{month: 'numeric'}) + "-"+
                          new Date(customer.last_purchase.created_at).toLocaleString("en-US",{day: 'numeric'})
                          + ' | '+ new Date(customer.last_purchase.created_at).toLocaleTimeString('en-US') : '--' 
                        }}
                      </p>
                    </div>
                  </td>
                  <!-- last purchased block ends here -->

                  <!-- purchased volume block starts here -->
                  <td style="position: relative;" @click.prevent="previewCustomer(customer,index,'solid',$event)">
                    <div class="tableBodyCtmTxt" data-open="true">
                      <el-tooltip 
                        class="box-item" 
                        effect="dark" 
                        :content="customer.purchase_volume ? `${settings.currency_symbol + ' ' + customer.purchase_volume}` : `${settings.currency_symbol + ' 0'}`" 
                        placement="left"
                      >
                        <p data-open="true">
                          <b data-open="true">
                            {{
                              customer.purchase_volume ?
                              settings.currency_symbol + " " + Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(customer.purchase_volume) 
                              : settings.currency_symbol + " " + 0
                            }}
                          </b>
                        </p>
                      </el-tooltip>
                    </div>
                  </td>
                  <!-- purchased volume block ends here -->

                  <!-- cart block starts here -->
                  <td @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="ctmDataTableActionBtn" data-open="true">
                      <button 
                        :disabled="tourIsActive" 
                        @click.prevent="exploreCustomerCart(customer)" 
                        data-open="false" 
                        style="color:#15223D; font-size: 24px;" 
                        v-if="customer.id != -1"
                      >
                        <el-tooltip 
                          class="box-item" 
                          effect="dark" 
                          :content="customer.cart.cart_items_count == 1 ? `${customer.cart.cart_items_count} Item`: `${customer.cart.cart_items_count} Items`" 
                          placement="right"
                        >
                          <i class="fa fa-shopping-cart"></i>
                        </el-tooltip>
                      </button>
                    </div>
                  </td>
                  <!-- cart block ends here -->

                  <!-- active/disable block starts here -->
                  <td @click.prevent="previewCustomer(customer,index,'input',$event)">
                    <div class="actionSwitch" data-open="true">
                      <label class="switch" data-open="false">
                        <input type="checkbox"
                          :id="`customSwitch-p-${customer.id}`"
                          v-model="customer.isActive"
                          :value="customer.isActive"
                          :disabled="selectedCustomer.id == -1"
                          data-open="false"
                        >
                        <span class="slider" data-open="false" @click.stop="updateCustomerStatus(customer,index)"></span>
                      </label>
                    </div>
                  </td>
                  <!-- active/disable block ends here -->

                </tr>

                <!-- load more block starts here -->
                <tr v-if="isPending">
                  <td>
                    <div class="ctmCheckWrap">
                      <label class="ctmTableCheckContainer">
                        <vue-skeleton-loader
                          type="rect"
                          :width="17"
                          :height="20"
                          animation="fade"
                        />
                      </label>
                      <div class="editFieldTitle customerNameField">
                        <vue-skeleton-loader
                          type="circle"
                          :width="36"
                          :height="36"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="ctmCheckWrap">
                      <div class="ctmTableImgWrap">
                        <vue-skeleton-loader
                          type="rect"
                          :width="80"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="ctmCheckWrap">
                      <div class="editFieldTitle customerNameField">
                        <vue-skeleton-loader
                          type="rect"
                          :width="80"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td class="mainTableTitle">
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="140"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="100"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="54"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="112"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="120"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="120"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="actionSwitch">
                      <vue-skeleton-loader
                        type="rect"
                        :width="70"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="actionSwitch">
                      <vue-skeleton-loader
                        type="rect"
                        :width="70"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                </tr>
                <!-- load more block ends here -->

              </tbody>
            </table>
          </div>
          
          <!-- customer add/update form starts here -->
          <transition name="record-form">
            <div class="productSlideBox activeRow" ref="viewInputForm" v-if="selectedCustomer.id">
              <div class="tableActionBtns" id="customer-details-preview">
                <div class="productTableSideBar">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="viewOrderTitle">
                        <p>
                          {{ 
                            (selectedCustomer.first_name + " " + selectedCustomer.last_name).length > 20 ?
                            (selectedCustomer.first_name + " " + selectedCustomer.last_name).substr(0,20) + "..." : 
                            (selectedCustomer.first_name + " " + selectedCustomer.last_name) 
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="orderViewNav">
                        <ul>
                          <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                            <li class="sm-extand-hide">
                              <button @click.prevent="expand" :disabled="tourIsActive">
                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                                  <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                    <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                    <path d="m16.5.522-6 5.907"/>
                                    <path d="m11 16.521 5.5.002-.013-5.5"/>
                                    <path d="m16.5 16.429-6-5.907"/>
                                    <path d="m.5 5.5v-5h5.5"/>
                                    <path d="m6.5 6.429-6-5.907"/>
                                    <path d="m6 16.516-5.5.007v-5.023"/>
                                    <path d="m6.5 10.5-6 6"/>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                            <li>
                              <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                  <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                    <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                    <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                      <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                      <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sectionTabsLink" style="padding: 15px 20px;">
                  <ul onclick="window.myFunction(event)">
                    <li v-if="!tourIsActive">
                      <a class="active">Customer Info</a>
                    </li>
                  </ul>
                  <div class="statusColumnBox">
                    <div class="actionSwitch" id="customer-status-switch">
                      <label class="switch">
                        <input 
                          type="checkbox"
                          v-model="selectedCustomer.isActive"
                          :disabled="tourIsActive"
                        >
                        <span class="slider"></span>
                        <p class="ctmSwitchLabel">{{ selectedCustomer.isActive ? 'Active' : 'Disabled' }}</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="productTableInfoBlock">
                <div class="productTableInfoWhiteBox">

                  <!-- image for add customer -->
                  <div class="uploadNewUserImg" v-if="this.selectedCustomer.id == -1">
                    <div class="userDetailsImg">
                      <img 
                        :src="selectedCustomer.addImage ? selectedCustomer.addImage_src ? selectedCustomer.addImage_src : base_image_url + setting.store_id + '/user/' + selectedCustomer.addImage : '/images/customer_noImage.png' " 
                        alt=""
                        @error="handleImageError"
                      />
                    </div>
                    <div class="uploadImgFile" id="customer-image">
                      <input 
                        type="file" 
                        ref="addPreviewFileInput" 
                        @change.prevent="changeAddImage" 
                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                        :disabled="tourIsActive"
                      >
                      <button>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <span 
                    class="text-danger mx-auto" 
                    style="text-align:center; display:block" 
                    v-if="this.selectedCustomer.id == -1 && imageValidations.imgIsRequired"
                  >
                    Image field is required
                  </span>
                  
                  <!-- image for update customer -->
                  <div class="uploadNewUserImg" v-if="this.selectedCustomer.id != -1">
                    <div class="userDetailsImg">
                      <img 
                        :src="selectedCustomer.image_src" 
                        alt="" 
                        v-if="selectedCustomer.image"
                        @error="handleImageError"
                      />
                      <img 
                        :src="selectedCustomer.image_src ? base_image_url + settings.store_id + '/user/' + selectedCustomer.image_src : '/images/customer_noImage.png' " 
                        alt=""
                        @error="handleImageError" 
                        v-else
                      />
                    </div>
                    <div class="uploadImgFile" id="customer-image">
                      <input 
                        type="file" 
                        ref="previewFileInput" 
                        @change.prevent="handleChangeEditImage" 
                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                        :disabled="tourIsActive"
                      >
                      <button>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <span 
                    class="text-danger mx-auto" 
                    style="text-align:center; display:block" 
                    v-if="this.selectedCustomer.id != -1 && imageValidations.imgIsRequired"
                  >
                    Image field is required
                  </span>
                  
                  <ValidationObserver ref="customerObserver">
                    <form @submit.prevent="validate()">
                      <div class="row">
                        <div class="col-md-6">
                          <ValidationProvider name="first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="first-name-field">First Name<span>*</span></label>
                              <div class="tableFromFieldItem">
                                <input 
                                  type="text" 
                                  name="first-name-field" 
                                  id="first-name-field" 
                                  placeholder="First name" 
                                  v-model="selectedCustomer.first_name" 
                                  @input="handleFormat('update','f_name',null)"
                                  :disabled="tourIsActive"
                                >
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="last-name-field">Last Name<span>*</span></label>
                              <div class="tableFromFieldItem">
                                <input 
                                  type="text" 
                                  name="last-name-field" 
                                  id="last-name-field" 
                                  placeholder="Last name" 
                                  v-model="selectedCustomer.last_name" 
                                  @input="handleFormat('update','l_name',null)"
                                  :disabled="tourIsActive"
                                >
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="email-field">Email<span>*</span></label>
                              <div class="tableFromFieldItem">
                                <input 
                                  type="email" 
                                  name="email-field" 
                                  id="email-field" 
                                  placeholder="Email" 
                                  v-model="selectedCustomer.email"
                                  :disabled="tourIsActive"
                                >
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" id="mobile-no-field">
                          <div class="tableFromFieldBox">
                            <label for="mNumber">Mobile No.<span>*</span></label>
                            <div class="tableFromFieldItem">
                              <vue-tel-input 
                                v-model="selectedCustomer.mobile_no"
                                mode="international"
                                :onlyCountries="allowedCountries"
                                :inputOptions="contactFieldOptions"
                                ref="contactNoField"
                                :dropdownOptions="contactFieldDropDownOptions"
                                :validCharactersOnly=true 
                                @input="contactInputHandle"
                                @validate="validateContactNo"
                                @country-changed="countryChangeHandle"
                                @focus="contactFieldFocusHandle"
                                @open="dropDownOpenHandle"
                                @close="dropDownCloseHandle"
                                :disabled="tourIsActive"
                              >
                              </vue-tel-input>
                              <span class="text-danger" v-if="!countryIsSelected && contactFieldTouched">
                                Select a country from the dropdown menu or add the country code before the mobile no.
                              </span>
                              <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">
                                Enter a valid mobile no.
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" id="country-field">
                          <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="country">Country<span>*</span></label>
                              <div class="vueSelectBlock">
                                <Multiselect
                                  v-model="selectedCountry"
                                  id="country"
                                  :clearOnSelect="true"
                                  :options="countriesList"
                                  label="name"
                                  :multiple="false"
                                  :object="true"
                                  track-by="name"
                                  :hideSelected="false"
                                  :showNoResults="true"
                                  placeholder="Select country"
                                  :showOptions="true"
                                  :searchable="true"
                                  openDirection="bottom"
                                  :disabled="tourIsActive"
                                >
                                  <span slot="noResult">No country found matching the searched keyword.</span>
                                </Multiselect>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" id="state-field">
                          <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="state">State<span>*</span></label>
                              <div class="vueSelectBlock">
                                <Multiselect
                                  v-model="selectedState"
                                  id="state"
                                  :clearOnSelect="true"
                                  :options="listStates ? listStates : []"
                                  label="name"
                                  track-by="name"
                                  :hideSelected="false"
                                  :showNoResults="true"
                                  :multiple="false"
                                  :object="false"
                                  :showOptions="false"
                                  :searchable="true"
                                  :loading="statesLoading"
                                  openDirection="bottom"
                                  :placeholder="statesLoading ? 'Loading states....' : 'Select state'"
                                  :disabled="tourIsActive"
                                >
                                  <span slot="noOptions">To load the states, please select a country.</span>
                                  <span slot="noResult">No state found matching the searched keyword.</span>
                                </Multiselect>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" id="city-field">
                          <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="city">City<span>*</span></label>
                              <div class="vueSelectBlock">
                                <Multiselect
                                  v-model="selectedCity"
                                  id="city"
                                  :clearOnSelect="true"
                                  :options="listCities ? listCities : []"
                                  label="name"
                                  track-by="name"
                                  :hideSelected="false"
                                  :showNoResults="true"
                                  :multiple="false"
                                  :object="true"
                                  :showOptions="true"
                                  :searchable="true"
                                  :loading="citiesLoading"
                                  openDirection="bottom"
                                  :placeholder="citiesLoading ? 'Loading cities....' : 'Select city'"
                                  :disabled="tourIsActive"
                                >
                                  <span slot="noOptions">To load the cities, please select a state.</span>
                                  <span slot="noResult">No city found matching the searched keyword.</span>
                                </Multiselect>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="selectedCustomer.id == -1">
                          <ValidationProvider name="password" :rules="{required:true,min:8,max:250,regex:/^\S.*\S$/}" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="password-field">Password<span>*</span></label>
                              <div class="tableFromFieldItem passwordBtnIconPd">
                                <a href="" class="genPasswordBtn" @click.prevent="generatePassword('sliderForm',16)">Generate Password</a>
                                <input 
                                  :type="passwordFieldType ? 'password' : 'text'" 
                                  ref="passwordInput" 
                                  name="password-field" 
                                  id="password-field" 
                                  placeholder="Password" 
                                  autocomplete="true"
                                  v-model="selectedCustomer.password"
                                  :disabled="tourIsActive"
                                >
                                <span class="text-danger">{{ errors[0] }}</span>
                                <button class="passwordEyeBtn" type="button" @click.prevent="passwordFieldType = !passwordFieldType" :disabled="tourIsActive">
                                  <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordFieldType">
                                    <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                    <g id="eyeOpen" transform="translate(-355 -428)">
                                      <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                        <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                      </g>
                                      <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-12">
                          <ValidationProvider name="address line 1" rules="required|max:250" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="address-line-1-field">Address Line 1<span>*</span></label>
                              <div class="tableFromFieldItem">
                                <input 
                                  type="text" 
                                  name="address-line-1-field" 
                                  id="address-line-1-field" 
                                  placeholder="Address line 1" 
                                  v-model="selectedCustomer.address_line_1"
                                  :disabled="tourIsActive"
                                >
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-12">
                          <ValidationProvider name="address line 2" rules="max:250" v-slot="{ errors }">
                            <div class="tableFromFieldBox">
                              <label for="address-line-2-field">Address Line 2</label>
                              <div class="tableFromFieldItem">
                                <input 
                                  type="text" 
                                  name="address-line-2-field" 
                                  id="address-line-2-field" 
                                  placeholder="Address line 2" 
                                  v-model="selectedCustomer.address_line_2"
                                  :disabled="tourIsActive"
                                >
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="customerDetailformBtns">
                        <button type="button" @click.prevent="passwordFormOpen()" v-if="selectedCustomer.id != -1">Change Password</button>
                        <loaderBtn v-if="updateLoading"/>
                        <button type="submit" :disabled="tourIsActive" id="save-customer-btn" v-else>Save</button>
                      </div>
                    </form>
                  </ValidationObserver>
                  
                </div>
              </div>
            </div>
          </transition>
          <!-- customer add/update form ends here -->

        </div>
        <!-- data table ends here -->

        <!-- bottom action bar starts here -->
        <div class="row product-bottom-bar" v-show="checkedItems.length > 1">
          <div class="col-md-6 model-footer-main1">
            <div class="bottom-bar">
              <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != currentCustomerList.length">
                <i class="fa fa-check-square-o"></i>
                <p>SELECT ALL</p>
              </a>
              <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                  <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                </svg>
                <p>
                  CLEAR SELECTION
                </p>
              </a>
            </div>
            <div class="model-footer-2">
              {{ checkedItems.length }}
            </div>
          </div>
          <div class="col-md-6 model-footer-main1 product-row-reverse">
            <div class="bottom-bar">
              <a href="" class="model-footer-1 mr-4" @click.prevent="applyAction('active')">
                <p>ACTIVE</p>
              </a>
              <a href="" class="model-footer-1 mr-4" @click.prevent="applyAction('disable')">
                <p>DISABLE</p>
              </a>
            </div>
          </div>
        </div>
        <!-- bottom action bar ends here -->
         
      </div>
    </div>

    <!-- password reset modal starts here -->
    <password-reset-modal :moduleName="'customer'" ref="password_reset_modal" />
    <!-- password reset modal ends here -->

    <!-- customer cart modal starts here -->
    <record-preview-modal :moduleName="'customer'" :recordType="'cart'" ref="record_modal" />
    <!-- customer cart modal ends here -->

    <!-- vue tour -->
    <v-tour name="customersTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>

<script>
import { MessageBox, Message, Loading } from 'element-ui'
import _ from 'lodash';
import Multiselect from "vue-multiselect";
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import contactInput from "@/components/contactInput.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";
import RecordPreviewModal from "@/components/RecordPreviewModal/index.vue";
import passwordResetModal from "@/components/PasswordResetModal/index.vue";

export default {
  name: 'customers',
  metaInfo() {
    return {
      title: 'E-Commerce | Customers | YeetCommerce',
    };
  },
  data() {
    return {
      base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      selectedCustomer: {
        id: '',
        isPreview: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        address_line_1: '',
        address_line_2: '',
        image: '',
        image_src: '',
        addImage: null,
        addImage_src: '',
        city: null,
        orders_count: 0,
        isLoading: false,
        isActive: false,
        isTouched: false,
      },
      tempCustomer:{
        id: '',
        isPreview: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        city:'',
        address_line_1: '',
        address_line_2: '',
        image: '',
        image_src: '',
        addImage:null,
        addImage_src:'',
        orders_count: 0,
        isLoading: false,
        isActive: false,
        isTouched:false,
      },
      tempPreviewCustomer:{
        id: '',
        isPreview: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        city:'',
        address_line_1: '',
        address_line_2: '',
        image: '',
        image_src: '',
        addImage:null,
        addImage_src:'',
        orders_count: 0,
        isLoading: false,
        isActive: false,
        isTouched:false,
      },
      imageValidations:{
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      },
      customerSave:false,
      customerList: [],
      allowedCountries:[],
      currentCustomerList: [],
      updateLoading: false,
      boxIsLoading:false,
      isMasterSearched:false,
      checkedItems: [],
      listCities:[],
      passwordFieldType:true,
      countriesList: [],
      selectedCountry: null,
      listStates: [],
      selectedState: null,
      listCities: [],
      selectedCity: null,
      statesLoading:false,
      citiesLoading:false,
      //contact field handle
      allowedCountries:[],
      contactFieldOptions:{
        placeholder:"Mobile no.",
        showDialCode:true,
        type:'tel',
        maxlength:25,
      },
      contactFieldDropDownOptions:{
        showSearchBox:true,
        showDialCodeInSelection:false,
        showDialCodeInList:true,
        showFlags:true,
        placeholder:"Select a country"
      },
      contactIsValid:true,
      countryIsSelected:false,
      contactFieldTouched:false,
      selectedContactObj:{},
      selectedContactCountry:[],

      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#reload-data-btn',
          content: `<strong>Refresh Data</strong><br>It fetches new information seamlessly without a full refresh.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },  
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //search bar step
        {
          target: '#module-search',
          content: `<strong>Search Customer</strong><br>It enables you to search specific customers.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        //form steps
        {
          target: '#preview-record',
          content: `<strong>Customers Table</strong><br>You can preview and edit the added customers by clicking on each individual record present in the table.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#add-new-record',
          content: `Add a <strong>Customer</strong><br>By clicking this button, you can add a new customer and open a form to input and save the details.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#customer-details-preview',
          content: `<strong>Customer Details Form</strong><br>This form can be used to add details about the customer and save them.`,
          params: {
            highlight: false,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#customer-image',
          content: `<strong>Customer's Image</strong><br>Image of a customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#first-name-field',
          content: `<strong>Customer's First Name</strong><br>First name of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#last-name-field',
          content: `<strong>Customer's Last Name</strong><br>Last name of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#email-field',
          content: `<strong>Customer's Email Address</strong><br>Email address of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#mobile-no-field',
          content: `<strong>Customer's Mobile No.</strong><br>Mobile no. of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#country-field',
          content: `<strong>Customer's Country</strong><br>Country of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#state-field',
          content: `<strong>Customer's State</strong><br>State of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#city-field',
          content: `<strong>Customer's City</strong><br>City of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#password-field',
          content: `<strong>Customer's Password</strong><br>Password of the customer account can be added using this field. 
          A strong password can be generate by clicking the Generate Password option.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#address-line-1-field',
          content: `<strong>Customer's Address (Line 1)</strong><br>Address of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#address-line-2-field',
          content: `<strong>Customer's Address (Line 2)</strong><br>Address of the customer can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#table-first-name-field',
          content: `<strong>Customer's First Name</strong><br>First name of the customer can also be added/updated using this field.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'right'
          }
        },
        {
          target: '#table-last-name-field',
          content: `<strong>Customer's Last Name</strong><br>Last name of the customer can also be added/updated using this field.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'left'
          }
        },
        {
          target: '#table-email-field',
          content: `<strong>Customer's Email Address</strong><br>Email address of the customer can also be added/updated using this field.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'left'
          }
        },
        {
          target: '#expand-form',
          content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#closeSide',
          content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#customer-status-switch',
          content: `<strong>Customer's Status</strong><br>Customer status can be changed between active and inactive using this switch.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#save-customer-btn',
          content: `<strong>Save Customer Details</strong><br>Added customer details can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
      ],
      tourIsActive:false,
    }
  },
  components:{
    Multiselect,
    subHeader,
    moduleStats,
    contactInput,
    tutorialsAction,
    loaderBtn,
    moduleSearch,
    RecordPreviewModal,
    passwordResetModal,
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      customers:'customers_module/customers',
      customersCurrentPage:'customers_module/currentPage',
      customersLastPage:'customers_module/lastPage',
      customerSearchKeyword:'customers_module/keyword',
      isPending: 'customers_module/isPending',
      storeAllCountries: 'countries_module/allCountries'
    }),
  },
  watch:{
    'customers':{
      handler:function(val){
        
        this.boxIsLoading = false;
        
        if(this.selectedCustomer.id == -1){

          this.customerList = val;
          this.currentCustomerList = _.cloneDeep(this.customerList);

          this.currentCustomerList.unshift(this.selectedCustomer);

        }else if(this.tempPreviewCustomer.id != ''){

          this.customerList = val;
          this.currentCustomerList = _.cloneDeep(this.customerList);

          this.currentCustomerList[this.selectedCustomer.index] = this.selectedCustomer;

        }else{

          this.customerList = val;
          this.currentCustomerList = _.cloneDeep(this.customerList);

        }

        if(this.isMasterSearched){

          this.formPreview(this.currentCustomerList[0],0);

          this.isMasterSearched = false;

        }

      },
      deep:true
    },
    'selectedCustomer':{
      handler:function(value){

        if((value.id == -1) && (value.first_name || value.last_name || value.email
        || value.password || value.city 
        || value.address_line_1 || value.address_line_2 || value.image
        || value.image_src)){

          value.isTouched = true

        }else{

          value.isTouched = false

        }

      },
      deep:true
    },
    selectedCountry:{
      handler:function(value){

        if(value){

          this.listStates = [];
          this.selectedState = null;

          this.loadStates();

        }else{

          this.listStates = [];
          this.selectedState = null;

        }

      },deep:true
    },
    selectedState:{
      handler:function(value){

        if(value){

          this.listCities = [];
          this.selectedCity = null;
          
          this.loadCities();

        }else{

          this.listCities = [];
          this.selectedCity = null;

        }

      },deep:true
    },
    selectedCity:{
      handler:function(value){

        if(value){

          this.selectedCustomer.city = value;

        }else{

          this.selectedCustomer.city = null;

        }

      },deep:true
    },
    storeAllCountries:{
      handler:function(value){

        this.countriesList = value;
        
        value.forEach((country)=>{

          this.allowedCountries.push(country.iso2);

        });

      },deep:true
    },
  },
  methods:{
    handleScrollEvent(event){

      let element = this.$refs.customersTable;
      
      let maxScrollTop = element.scrollHeight - element.clientHeight;

      if(element.scrollTop >= maxScrollTop - 100){

        if(this.isPending == false && this.customersCurrentPage != this.customersLastPage){
          
          this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size:15 , page: this.customersCurrentPage + 1, loadData:'more'})
        
        }

      }

    },
    handleFormat(form,field,customer){
      
      if(form == 'update' && field == 'f_name'){

        let fname = this.selectedCustomer.first_name;
        fname = fname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        fname = fname.replace(/\s+/g, ' ')
        fname = fname.replace(/^\w/, (c) => c.toUpperCase());
        
        this.selectedCustomer.first_name = fname

      }else if (form == 'update' && field == 'l_name'){

        let lname = this.selectedCustomer.last_name;
        lname = lname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        lname = lname.replace(/\s+/g, ' ')
        lname = lname.replace(/^\w/, (c) => c.toUpperCase());

        this.selectedCustomer.last_name = lname

      }else if(form == 'table' && field == 'f_name'){

        let fname = customer.first_name;
        fname = fname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        fname = fname.replace(/\s+/g, ' ');
        fname = fname.replace(/^\w/, (c) => c.toUpperCase());

        customer.first_name = fname;

      }else if(form == 'table' && field == 'l_name'){

        let lname = customer.last_name;
        lname = lname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        lname = lname.replace(/\s+/g, ' ');
        lname = lname.replace(/^\w/, (c) => c.toUpperCase());

        customer.last_name = lname

      }

    },
    generatePassword(form,length){

      if(form == 'sliderForm'){

        if(!this.tourIsActive){

          let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}";
          let retVal = "";

          for(var i = 0, n = charset.length; i < length; ++i){

            retVal += charset.charAt(Math.floor(Math.random() * n));

          }

          this.$refs.passwordInput.value = retVal;
          this.selectedCustomer.password = retVal;

          this.$refs.passwordInput.focus();

        }

      }

    },
    passwordFormOpen(){

      this.$refs.password_reset_modal.openModal(this.selectedCustomer.id);

    },
    generateUrl(customer){

      if(customer.image){

        if(typeof(customer.image) == "string"){

          return this.base_image_url + this.settings.store_id + '/user/' + customer.image

        }else{

          return customer.image_src

        }
        
      }else if(customer.image_src){
        
        return this.base_image_url + this.settings.store_id + '/user/' + customer.image_src

      }

    },
    customerSelection(customer){

      this.tempCustomer = _.cloneDeep(customer);
      
    },
    handleOTGUpdate(customer,index){
      
      if(this.selectedCustomer.id == ''){

        if(customer.id != -1){

          if((this.tempCustomer.first_name != customer.first_name) ||
          (this.tempCustomer.last_name != customer.last_name) ||
          (this.tempCustomer.email != customer.email) ||
          (this.tempCustomer.mobile_no != customer.mobile_no)){

            if(customer.first_name.trim().length == 0){

              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The first name field is required.",
              })
              
              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

              this.tempCustomer = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                city:'',
                address_line_1: '',
                address_line_2: '',
                image: '',
                image_src: '',
                isLoading: false,
                isActive: false,
              }

              return

            }
            if(customer.first_name.trim().length > 50){

              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The first name field may not be greater than 50 characters.",
              })

              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

              this.tempCustomer = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                city:'',
                address_line_1: '',
                address_line_2: '',
                image: '',
                image_src: '',
                isLoading: false,
                isActive: false,
              }

              return

            }

            if(customer.last_name.trim().length == 0){

              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The last name field is required.",
              });

              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
              
              this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

              this.tempCustomer = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                city:'',
                address_line_1: '',
                address_line_2: '',
                image: '',
                image_src: '',
                isLoading: false,
                isActive: false,
              }

              return

            }
            if(customer.last_name.trim().length > 50){

              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The last name field may not be greater than 50 characters.",
              });

              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

              this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

              this.tempCustomer = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                city:'',
                address_line_1: '',
                address_line_2: '',
                image: '',
                image_src: '',
                isLoading: false,
                isActive: false,
              }

              return

            }

            if(customer.email.trim().length == 0){

              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The email field is required.",
              });

              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
              
              this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

              this.currentCustomerList[index].email = this.tempCustomer.email;

              this.tempCustomer = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                city:'',
                address_line_1: '',
                address_line_2: '',
                image: '',
                image_src: '',
                isLoading: false,
                isActive: false,
              }

              return

            }
            if(customer.email.length != 0 && customer.email){

              let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/);

              let isValid = regex.test(customer.email)

              if(!isValid){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The email field must be a valid email.",
                })

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
              
                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.currentCustomerList[index].email = this.tempCustomer.email;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }

              if(isValid && customer.email.length > 255){

                this.$notify({
                  title: 'Alert',
                  type: 'warning',
                  message: "The email must not be greater than 250 characters.",
                })

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
              
                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.currentCustomerList[index].email = this.tempCustomer.email;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }

            }

            if(!customer.address_line_1){
          
              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The address information must be added first.",
              });

              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
              
              this.currentCustomerList[index].last_name = this.tempCustomer.last_name;
              
              this.currentCustomerList[index].email = this.tempCustomer.email;

              this.currentCustomerList[index].address_line_1 = this.tempCustomer.address_line_1;

              this.tempCustomer = {
                id: '',
                isPreview: false,
                first_name: '',
                last_name: '',
                email: '',
                mobile_no: '',
                city:'',
                address_line_1: '',
                address_line_2: '',
                image: '',
                image_src: '',
                isLoading: false,
                isActive: false,
              }

              return

            }

            MessageBox.confirm(
              'You have unsaved changes, save and proceed?',
              'Confirm',
              {
                distinguishCancelAndClose: true,
                confirmButtonText: 'Save',
                cancelButtonText: 'Discard Changes',
              }
            ).then(() => {

              this.updateDataTableRecord(customer,index);

            }).catch((error) => {

              this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
              this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

              this.currentCustomerList[index].mobile_no = this.tempCustomer.mobile_no;
              this.currentCustomerList[index].email = this.tempCustomer.email;

              this.$notify({
                type: "info",
                title: "Changes Discarded",
                message: "Changes discarded successfully",
              });

              this.tempCustomer = [];

            });

          }

        }

      }else{

        if(customer.id != -1){

          if(customer.id != this.selectedCustomer.id){

            if((this.tempCustomer.first_name != customer.first_name) ||
            (this.tempCustomer.last_name != customer.last_name) ||
            (this.tempCustomer.email != customer.email) ||
            (this.tempCustomer.mobile_no != customer.mobile_no)){

              if(customer.first_name.trim().length == 0){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The first name field is required.",
                })

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }
              if(customer.first_name.trim().length > 50){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The first name field may not be greater than 50 characters.",
                })

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }

              if(customer.last_name.trim().length == 0){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The last name field is required.",
                });

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }
              if(customer.last_name.trim().length > 50){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The last name field may not be greater than 50 characters.",
                });

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }

              if(customer.email.trim().length == 0){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The email field is required.",
                });

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.currentCustomerList[index].email = this.tempCustomer.email;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }
              if(customer.email.trim().length != 0 && customer.email){

                let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/);

                let isValid = regex.test(customer.email)

                if(!isValid){

                  this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "The email field must be a valid email.",
                  })

                  this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                  this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                  this.currentCustomerList[index].email = this.tempCustomer.email;
                  
                  this.tempCustomer = {
                    id: '',
                    isPreview: false,
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile_no: '',
                    city:'',
                    address_line_1: '',
                    address_line_2: '',
                    image: '',
                    image_src: '',
                    isLoading: false,
                    isActive: false,
                  }

                  return

                }

                if(isValid && customer.email.trim().length > 255){

                  this.$notify({
                    title: 'Alert',
                    type: 'warning',
                    message: "The email must not be greater than 250 characters.",
                  })

                  this.currentCustomerList[index].email = this.tempCustomer.email;
                  
                  this.tempCustomer = {
                    id: '',
                    isPreview: false,
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile_no: '',
                    city:'',
                    address_line_1: '',
                    address_line_2: '',
                    image: '',
                    image_src: '',
                    isLoading: false,
                    isActive: false,
                  }

                  return

                }

              }

              if(!customer.address_line_1){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The address information must be added first.",
                });

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;

                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.currentCustomerList[index].email = this.tempCustomer.email;

                this.currentCustomerList[index].address_line_1 = this.tempCustomer.address_line_1;

                this.tempCustomer = {
                  id: '',
                  isPreview: false,
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_no: '',
                  city:'',
                  address_line_1: '',
                  address_line_2: '',
                  image: '',
                  image_src: '',
                  isLoading: false,
                  isActive: false,
                }

                return

              }

              MessageBox.confirm(
                'You have unsaved changes, save and proceed?',
                'Confirm',
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Discard Changes',
                }
              ).then(() => {

                this.updateDataTableRecord(customer,index);
                
              }).catch((error) => {

                this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
                this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

                this.currentCustomerList[index].mobile_no = this.tempCustomer.mobile_no;
                this.currentCustomerList[index].email = this.tempCustomer.email;

                this.$notify({
                  type: "info",
                  title: "Changes Discarded",
                  message: "Changes discarded successfully",
                });

                this.tempCustomer = [];

              });

            }

          }

        }

      }

    },
    async updateDataTableRecord(manipulatedRecord,index){

      let form = manipulatedRecord;

      let formData = new FormData();
      formData.append('id', form.id);
      formData.append('first_name', form.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('last_name', form.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('email', form.email);
      formData.append('city', form.city.id);

      if(form.address_line_1){

        formData.append('address_line_1', form.address_line_1.replace(/\s+/g,' ').trim());

      }

      if(form.address_line_2){

        formData.append('address_line_2', form.address_line_2.replace(/\s+/g,' ').trim());
        
      }

      formData.append('password', form.password);
      formData.append('mobile_no', form.mobile_no.replace(/\s/g,''));
      
      formData.append('isActive', form.isActive ? 1 : 0);

      let loader = Loading.service({
        text: "The customer is being updated. Please wait!",
        fullscreen: true,
      });

      try{
        let res = await this.$axios.post('/updateCustomer', formData);
        if(res.data.status_code == '1019'){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          let customer = res.data.customer
          customer.isActive = customer.isActive ? true : false;

          let payload = {
            index: index,
            updatedCustomer: customer
          }
          this.$store.commit('customers_module/update_customers_list',payload);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.customerList[index] = res.data.customer

        }
      }catch(error){

        this.currentCustomerList[index].first_name = this.tempCustomer.first_name;
        this.currentCustomerList[index].last_name = this.tempCustomer.last_name;

        this.currentCustomerList[index].mobile_no = this.tempCustomer.mobile_no;
        this.currentCustomerList[index].email = this.tempCustomer.email;

        this.tempCustomer = [];

        if(error.response){

          if(error.response.data.error.includes("aleardy exists")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loader.close();

      }

    },
    resetImageValidations(){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

    },
    handleChangeEditImage(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){
        
        const file = e.target.files[0];

        if(file.name.length > 50){

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file name must not exceed 50 characters.",
          }); 

          this.imageValidations.imgIsRequired = false

          if(this.$refs.previewFileInput){

            this.$refs.previewFileInput.value = ''

          }

          return

        }

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
        
          this.selectedCustomer.image = file;
          this.selectedCustomer.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false

        }else if(file.type.includes('image/')){
          
          let uploadedFileType = file.type.slice(6)
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

          // if(!this.selectedCustomer.image){
          //   this.imageValidations.imgIsRequired = true
          // }
          
          // this.imageValidations.imgInvalidFormat = true

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

          // if(!this.selectedCustomer.image){
          //   this.imageValidations.imgIsRequired = true
          // }

          // this.imageValidations.isNotImg = true

        }

      }
      
    },
    changeAddImage(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){
      
        const file = e.target.files[0];

        if(file.name.length > 50){

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file name must not exceed 50 characters.",
          }); 

          this.imageValidations.imgIsRequired = false

          if(this.$refs.addPreviewFileInput){

            this.$refs.addPreviewFileInput.value = ''

          }

          return

        }

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.selectedCustomer.addImage = file;
          this.selectedCustomer.addImage_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false

          if(this.$refs.addPreviewFileInput){

            this.$refs.addPreviewFileInput.value = ''

          }

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6)
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

          // if(!this.selectedCustomer.addImage){
          //   this.imageValidations.imgIsRequired = true
          // }
          
          // this.imageValidations.imgInvalidFormat = true

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

          // if(!this.selectedCustomer.addImage){
          //   this.imageValidations.imgIsRequired = true
          // }

          // this.imageValidations.isNotImg = true

        }

      }

    },

    //contact no. methods starts here
    // setContactFieldValidations(validations){
      
    //   this.contactIsValid = validations.isContactValid
    //   this.countryIsSelected = validations.isCountrySelected
    //   this.contactFieldTouched = validations.isFieldTouched
    //   this.selectedContactObj = validations.contactObj

    //   this.selectedCustomer.mobile_no = validations.addContactNo

    // },
    resetContactFieldValidations(){

      this.contactIsValid = true
      this.countryIsSelected = true
      this.contactFieldTouched = false

    },
    validateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.contactIsValid = contactObj.valid

        if(!this.contactIsValid){

          this.contactFieldTouched = true

        }else{

          this.contactFieldTouched = false

        }

      }

    },
    contactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.countryIsSelected = false

      }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.countryIsSelected = false

      }else if(typeof numberObj.country != 'undefined' && 
      typeof numberObj.valid == 'undefined' &&
      numberObj.formatted.length == numberObj.country.dialCode + 1){
      
        this.countryIsSelected = false

      }else{

        this.countryIsSelected = true
        
      }

      this.selectedContactObj = numberObj

    },
    dropDownOpenHandle(){

      this.countryObjBeforeChange = this.selectedContactObj
      
    },
    dropDownCloseHandle(){

      if((this.selectedCustomer.mobile_no).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

        if((this.countryObjBeforeChange.country != this.selectedContactObj.country)){

          this.selectedCustomer.mobile_no = "+" + this.selectedContactCountry.dialCode 

        }

      }

      this.countryIsSelected = true

    },
    countryChangeHandle(countryObj){

      this.selectedContactCountry = countryObj

    },
    contactFieldFocusHandle(){

      this.contactFieldTouched = true

    },
    //contact no. methods ends here
    
    async validate(action){

      this.$refs.customerObserver.validate().then(success => {

        if(!success){

          const errors = Object.entries(this.$refs.customerObserver.errors)
          .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

          this.$refs.customerObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          if(typeof this.selectedContactObj.valid == 'undefined'){

            this.contactIsValid = false
            this.contactFieldTouched = true

            document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })
            
          }

        }else{

          if(this.selectedCustomer.id == -1){

            if(typeof this.selectedContactObj.valid == 'undefined'){

              this.contactIsValid = false
              this.contactFieldTouched = true

            }

            if(this.contactIsValid && this.countryIsSelected){

              this.handleAddCustomer();

            }else{

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }else{

            if(typeof this.selectedContactObj.valid == 'undefined'){

              this.contactIsValid = false
              this.contactFieldTouched = true

            }

            if(this.contactIsValid && this.countryIsSelected){

              this.handleUpdateCustomer();

            }else{

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }

        }

      });

    },
    async handleAddCustomer(){

      let form = this.selectedCustomer;
        
      let formData = new FormData();
      formData.append('first_name', form.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('last_name', form.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('email', form.email);
      formData.append('city', this.selectedCity.id);
      formData.append('address_line_1', form.address_line_1.replace(/\s+/g,' ').trim());

      if(form.address_line_2){

        formData.append('address_line_2', form.address_line_2.replace(/\s+/g,' ').trim());

      }

      formData.append('password', form.password);
      formData.append('mobile_no',form.mobile_no.replace(/\s/g,''))
      formData.append('isActive', form.isActive ? 1 : 0);

      if(form.addImage){

        formData.append('image', form.addImage);

      }

      this.updateLoading = true;
      try{
        let res = await this.$axios.post('/addCustomer', formData);
        if(res.data.status_code == '1018'){

          let customer = res.data.customer;
          customer.isActive = customer.isActive ? true : false;

          this.customerSave = true;

          this.resetPreview();

          this.$store.commit('customers_module/add_customers_list',customer);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
          
          this.$store.commit('contacts_module/CLEAR_MODULE');

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.image){
                        
            this.$message({
              type: 'error',
              showClose: true,
              message: "We encountered difficulties processing the provided image.",
            });

          }else if(error.response.data.error.includes("already exists")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }
        
      }finally{

        this.updateLoading = false;

      }
      
    },
    async handleUpdateCustomer(){
      
      let form = this.selectedCustomer;
      
      let formData = new FormData();
      formData.append('id', form.id);
      formData.append('first_name', form.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('last_name', form.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('email', form.email);
      formData.append('city', this.selectedCity.id);
      formData.append('address_line_1', form.address_line_1.replace(/\s+/g,' ').trim());
      
      if(form.address_line_2){

        formData.append('address_line_2', form.address_line_2.replace(/\s+/g,' ').trim());

      }

      formData.append('password', form.password);
      formData.append('mobile_no',form.mobile_no.replace(/\s/g,''))
      
      formData.append('isActive', form.isActive ? 1 : 0);
      
      if(form.image){

        formData.append('image',form.image)
        
      }

      this.updateLoading = true;
      try{
        let res = await this.$axios.post('/updateCustomer', formData);
        if(res.data.status_code == '1019'){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          let customer = res.data.customer
          customer.isActive = customer.isActive ? true : false;

          let customerIndex = this.customerList.findIndex((listedCustomer) => listedCustomer.id == customer.id)

          let payload = {
            index: customerIndex,
            updatedCustomer: customer
          }

          this.currentCustomerList[customerIndex] = customer

          this.$store.commit('customers_module/update_customers_list',payload);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          if(this.selectedCustomer.id == customer.id){

            this.customerSave = true;

            this.resetPreview();

          }

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("aleardy exists")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){
            
            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.updateLoading = false;

      }

    },
    deleteCustomer(customer,index){

      MessageBox.confirm(
        'Are you sure you want to delete ?',
        'Warning',
        {
          type: 'warning',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        }
      ).then(async ()=>{
        
        try{
          let res = await this.$axios.post('/deleteCustomer', {id: customer.id});
          if(res.data.status_code == '1021'){
            
            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            this.$store.commit('customers_module/delete_customers_list',index);

            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
            
          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }

      })

    },
    async updateCustomerStatus(customer,i){

      if(!this.tourIsActive){

        let loader = Loading.service({
          text: "The customer is undergoing status update. Please wait!",
          fullscreen: true,
        });

        let formData = new FormData()

        formData.append('customer_id', customer.id);
        formData.append('status', customer.isActive ? 0 : 1);

        customer.isActive = !customer.isActive

        try{
          let res = await this.$axios.post('/changeCustomerStatus',formData);
          if(res.data.status_code == "10191"){
            
            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            })
            let payload = {
              index: i,
              updatedCustomer: res.data.customer,
            }

            this.$store.commit('customers_module/update_customer_status',payload);

          }
        }catch(error){

          customer.isActive = !customer.isActive

          if(error.response){

            if(error.response.data.error.id){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error.id[0],
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

        }

      }

    },
    previewCustomer(selectedCustomer,i,field,e){

      if(field == "input"){

        if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

          if(selectedCustomer.id != -1){

            this.formPreview(selectedCustomer,i);

          }

        }

      }else{

        if(!this.tourIsActive){

          if(selectedCustomer.id != -1){

            this.formPreview(selectedCustomer,i);

          }

        }

      }

    },
    formPreview(selectedCustomer,i){

      if(this.selectedCustomer.id == -1){

        MessageBox.confirm(
          "Are you sure? unsaved changes will be discarded", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {

          let index = this.currentCustomerList.findIndex((customer) => customer.id == -1);

          if(index != -1){

            this.currentCustomerList.splice(index,1); 

          }

          if(!this.customerSave){

            let tempIndex = this.currentCustomerList.findIndex(x=>x.id == this.tempPreviewCustomer.id);

            this.currentCustomerList[tempIndex] = this.tempPreviewCustomer

          }

          this.checkedItems = []

          this.resetContactFieldValidations();

          let customer = _.cloneDeep(selectedCustomer);

          this.selectedCustomer = selectedCustomer
          this.selectedCustomer.mobile_no = selectedCustomer.mobile_no ? selectedCustomer.mobile_no : ''
          this.selectedCustomer.index = i
          this.selectedCustomer.image_src = selectedCustomer.image ? selectedCustomer.image : null;
          this.selectedCustomer.image = null;
          this.selectedCustomer.isPreview = true;
          this.selectedCustomer.isLoading = false;

          if(customer.city){

            this.selectedCountry = customer.city.state.country

          }else{

            this.selectedCountry = null;
            this.selectedState = null;
            this.selectedCity = null;
            
          }

          this.updateLoading = false;

          this.tempPreviewCustomer = _.cloneDeep(customer)

        }).catch(() => {})

      }else{

        let index = this.currentCustomerList.findIndex((customer) => customer.id == -1);

        if(index != -1){

          this.currentCustomerList.splice(index,1); 

        }

        if(!this.customerSave && (this.tempPreviewCustomer.id != selectedCustomer.id)){

          let tempIndex = this.currentCustomerList.findIndex(x=>x.id == this.tempPreviewCustomer.id);

          if(tempIndex != -1){

            this.currentCustomerList[tempIndex] = _.cloneDeep(this.tempPreviewCustomer)

          }

        }

        if(this.tempPreviewCustomer.id != '' && this.tempPreviewCustomer.id == selectedCustomer.id){
          
          let tempIndex = this.currentCustomerList.findIndex(x=>x.id == this.tempPreviewCustomer.id);

          this.currentCustomerList[tempIndex] = _.cloneDeep(this.customerList[tempIndex])
          
          this.checkedItems = []

          this.resetContactFieldValidations();

          let customer = _.cloneDeep(this.currentCustomerList[tempIndex]);

          this.selectedCustomer = this.currentCustomerList[tempIndex]
          this.selectedCustomer.mobile_no = this.currentCustomerList[tempIndex].mobile_no ? this.currentCustomerList[tempIndex].mobile_no : ''
          this.selectedCustomer.index = i
          this.selectedCustomer.image_src = customer.image ? customer.image : customer.image_src ? customer.image_src : null;
          this.selectedCustomer.image = null;
          this.selectedCustomer.isPreview = true;
          this.selectedCustomer.isLoading = false;

          if(customer.city){

            if(customer.city.state){

              if(customer.city.state.country){

                this.selectedCountry = customer.city.state.country

              }

            }

          }else{

            this.selectedCountry = null;
            this.selectedState = null;
            this.selectedCity = null;

          }

          this.updateLoading = false;

          this.tempPreviewCustomer = _.cloneDeep(customer)

        }else{
          
          this.checkedItems = []

          this.resetContactFieldValidations();

          let customer = _.cloneDeep(selectedCustomer);

          this.selectedCustomer = selectedCustomer
          this.selectedCustomer.mobile_no = selectedCustomer.mobile_no ? selectedCustomer.mobile_no : ''
          this.selectedCustomer.index = index
          this.selectedCustomer.image_src = customer.image ? customer.image : customer.image_src ? customer.image_src : null;
          this.selectedCustomer.image = null;
          this.selectedCustomer.isPreview = true;
          this.selectedCustomer.isLoading = false;

          if(customer.city){

            this.selectedCountry = customer.city.state.country

          }else{

            this.selectedCountry = null;
            this.selectedState = null;
            this.selectedCity = null;

          }

          this.updateLoading = false;

          this.tempPreviewCustomer = _.cloneDeep(selectedCustomer)

        }

      }

    },
    closePreview(){

      if(this.selectedCustomer.isTouched){

        MessageBox.confirm(
          "Do you want to close? Entered information will be discarded.", 
          "Warning", 
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(async () => {

          let index = this.currentCustomerList.findIndex((customer) => customer.id == -1);

          if(index != -1){

            this.currentCustomerList.splice(index,1); 

          }

          if(!this.customerSave){

            let tempIndex = this.currentCustomerList.findIndex(x=>x.id == this.tempPreviewCustomer.id);

            if(tempIndex != -1){

              this.currentCustomerList[tempIndex] = this.tempPreviewCustomer

            }

          }

          this.selectedCustomer = {
            id: '',
            isPreview: false,
            first_name: '',
            last_name: '',
            email: '',
            mobile_no: '',
            address_line_1: '',
            address_line_2: '',
            image: '',
            image_src: '',
            isLoading: false,
            isActive: false,
            isTouched:false,
          }

          this.tempPreviewCustomer = this.selectedCustomer
          
          this.selectedCountry = null
          this.selectedState = null
          this.selectedCity = null
          this.customerSave = false

          this.isMasterSearched = false

          // this.contactFieldTouched = false
          
          if(this.$refs.contactInputfield){

            this.$refs.contactInputfield.resetValues();

          }

          if(this.$refs.customerObserver){

            this.$refs.customerObserver.reset();

          }

          this.resetImageValidations();

        });

      }else{

        let index = this.currentCustomerList.findIndex((customer) => customer.id == -1);

        if(index != -1){

          this.currentCustomerList.splice(index,1); 

        }
        
        if(!this.customerSave){

          let tempIndex = this.currentCustomerList.findIndex(x=>x.id == this.tempPreviewCustomer.id);

          if(tempIndex != -1){

            this.currentCustomerList[tempIndex] = this.tempPreviewCustomer

          }

        }

        this.selectedCustomer = {
          id: '',
          isPreview: false,
          first_name: '',
          last_name: '',
          email: '',
          mobile_no: '',
          address_line_1: '',
          address_line_2: '',
          image: '',
          image_src: '',
          isLoading: false,
          isActive: false,
          isTouched:false,
        }

        this.tempPreviewCustomer = this.selectedCustomer

        this.selectedCountry = null
        this.selectedState = null
        this.selectedCity = null
        this.customerSave = false

        this.isMasterSearched = false

        // this.contactFieldTouched = false

        if(this.$refs.contactInputfield){

          this.$refs.contactInputfield.resetValues();

        }

        if(this.$refs.customerObserver){

          this.$refs.customerObserver.reset();

        }

        this.resetImageValidations()

      }
        
    },
    resetPreview(){
      
      let index = this.currentCustomerList.findIndex((customer) => customer.id == -1);

      if(index != -1){

        this.currentCustomerList.splice(index,1); 

      }

      if(!this.customerSave && (this.tempPreviewCustomer.id != '')){

        let tempIndex = this.currentCustomerList.findIndex(x=>x.id == this.tempPreviewCustomer.id);

        if(tempIndex != -1){

          this.currentCustomerList[tempIndex] = this.tempPreviewCustomer

        }

      }

      this.selectedCustomer = {
        id: '',
        isPreview: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        address_line_1: '',
        address_line_2: '',
        addImage:null,
        addImage_src:'',
        image: '',
        image_src: '',
        isLoading: false,
        isActive: false,
        isTouched:false,
      }
      
      this.tempPreviewCustomer = this.selectedCustomer
      this.selectedCountry = null
      this.selectedState = null
      this.selectedCity = null

      this.customerSave = false

      this.resetContactFieldValidations()

      if(!this.isMasterSearched){
        
        if(this.$refs.customerObserver){

          this.$refs.customerObserver.reset()

        }
        
      }

      this.resetImageValidations();

    },
    createCustomer(){

      if(this.boxIsLoading){

        this.$notify({
          type: "info",
          title: "Please Wait",
          message: "Customers are being retrieved.",
        });

        return

      }

      if(this.updateLoading){

        this.$notify({
          type: "info",
          title: "Please Wait",
          message: "A customer is currently being processed.",
        });

        return

      }

      let index = this.customerList.findIndex(x=>x.id == -1);

      if(index != -1){

        // this.$notify({
        //   type: "error",
        //   title: "Can't Add New Customer",
        //   message: "you have an unsaved customer in the list",
        // });

        return
      }

      this.closePreview();

      this.selectedCustomer = {
        id: -1,
        isPreview: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        address_line_1: '',
        address_line_2: '',
        image: '',
        image_src: '',
        addImage:null,
        addImage_src:'',
        orders_count: 0,
        isLoading: false,
        isActive: true,
        isTouched:false,
      }

      this.countriesList.forEach((country)=>{

        if(this.settings.country.name == country.name){

          if(this.settings.country.phonecode.startsWith("+")){

            this.selectedCustomer.mobile_no = this.settings.country.phonecode.replace(/-/g, '')

          }else{

            this.selectedCustomer.mobile_no = "+" + (this.settings.country.phonecode).replace(/-/g, '')

          }

        }

      })

      let tempCustomer = this.selectedCustomer

      this.currentCustomerList.unshift(tempCustomer)

      this.passwordFieldType = true;

      this.selectedCountry = null
      this.selectedState = null
      this.selectedCity = null

      if(this.$refs.contactInputfield){

        this.$refs.contactInputfield.resetValues();

      }

      if(this.$refs.customerObserver){

        this.$refs.customerObserver.reset();

      }

      this.checkedItems = [];

      this.resetContactFieldValidations();

    },
    expand(){

      this.$refs.viewInputForm.classList.toggle('fullWidthSlide');
      
    },
    customerKeyEvent(e){

      if(e.key == 'Escape'){

        if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.$refs.viewInputForm.classList.remove('fullWidthSlide');

        }else if(this.$refs.viewInputForm.classList.contains('activeRow')){
          
          this.closePreview();

        }

      }

    },  
    checkedItemHandle(customerId){

      if(!this.tourIsActive && customerId != -1 && !this.selectedCustomer.id){

        let index = this.checkedItems.findIndex(item => item == customerId);
        
        if(index != -1){
          
          this.checkedItems.splice(index,1)

        }else{

          this.checkedItems.push(customerId)

        }

      }
    },
    toggleIsCheckedAll(){

      if(!this.tourIsActive ){

        if(this.checkedItems.length == this.currentCustomerList.length){

          this.checkedItems = [];

        }else if(this.checkedItems.length < this.currentCustomerList.length){

          this.checkedItems = [];

          this.currentCustomerList.forEach((customer) => {

            this.checkedItems.push(customer.id);

          });

        }

      }
    },
    async applyAction(action){
      
      MessageBox.confirm(
        'Do you really want to make the changes?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then( async () => {

        let loader = Loading.service({
          text: "The selected customers are undergoing status update. Please wait!",
          fullscreen: true,
        });

        let formData = new FormData();

        formData.append('resource_type', 'customer');
        formData.append("resource_ids", JSON.stringify(this.checkedItems));
        formData.append("action", action);

        try{
          let res = await this.$axios.post('/bulk-action',formData);
          if(res.data.status_code == '2031'){
            
            this.$notify({
              type: 'success',
              title: 'Success',
              message: "Customers status has been successfully changed.",
            });

            let payload = {
              action: 'statusChange',
              status: action == "active" ? 1 : 0,
              listIds: this.checkedItems
            }

            this.$store.commit('customers_module/customers_bulk_action',payload);

            this.checkedItems = [];

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          loader.close();

        }
        
      }).catch(()=>{})

    },
    clearSelection(){
            
      MessageBox.confirm(
        'Do you really want to uncheck the selected records?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async () => {

        this.checkedItems = [];

      }).catch(() => {})

    },
    searchCustomer(keyword){

      this.$refs.module_search.searchedKeyword = keyword

      this.$refs.module_search.search();

    },
    async loadStates(){

      this.statesLoading = true;
      try{
        
        let res = await this.$axios.get('states/all',{params:{country_id:this.selectedCountry.id}});
        if(res.data.status_code == "1010"){

          this.listStates = res.data.states;

          if(this.selectedCountry && this.tempPreviewCustomer.city && this.tempPreviewCustomer.city.state.country_id == this.selectedCountry.id){

            let stateObj = {
              id: this.tempPreviewCustomer.city.state.id,
              name: this.tempPreviewCustomer.city.state.name,
              country_code: this.tempPreviewCustomer.city.state.country_code,
              country_id: this.tempPreviewCustomer.city.state.country_id,
              fips_code: this.tempPreviewCustomer.city.state.fips_code,
              iso2: this.tempPreviewCustomer.city.state.iso2,
              latitude: this.tempPreviewCustomer.city.state.latitude,
              longitude: this.tempPreviewCustomer.city.state.longitude,
              wikiDataId: this.tempPreviewCustomer.city.state.wikiDataId,
            }

            this.selectedState = stateObj;

          }

        }

      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.statesLoading = false;

      }

    },
    async loadCities(){

      this.citiesLoading = true;
      try{
        let res = await this.$axios.get('cities/all',{params:{state_id:this.selectedState.id}});
        if(res.data.status_code == "1010"){

          this.listCities = res.data.cities;

          if(this.selectedCountry && this.tempPreviewCustomer.city && this.tempPreviewCustomer.city.state.country_id == this.selectedCountry.id){
            
            let cityObj = {
              id: this.tempPreviewCustomer.city.id,
              country_id: this.tempPreviewCustomer.city.country_id,
              country_code: this.tempPreviewCustomer.city.country_code,
              name: this.tempPreviewCustomer.city.name,
              state_id: this.tempPreviewCustomer.city.state_id,
              state_code: this.tempPreviewCustomer.city.state_code,
              latitude: this.tempPreviewCustomer.city.latitude,
              longitude: this.tempPreviewCustomer.city.longitude,
              wikiDataId: this.tempPreviewCustomer.city.wikiDataId,
            }

            this.selectedCity = cityObj;

          }

        }
      }finally{

        this.citiesLoading = false;

      }

    },  

    exploreCustomerCart(listedCustomer){

      if(listedCustomer.cart){

        if(listedCustomer.cart.cart_items_count != 0){

          this.$refs.record_modal.openModal(listedCustomer);

        }else{

          this.$notify({
            type: 'info',
            title: 'Empty Cart',
            message: `No items available in ${listedCustomer.first_name +" "+ listedCustomer.last_name}'s cart.`,
          });

        }

      }else{

        this.$message({
          type: 'error',
          showClose: true,
          message: "It seems there isn't any cart tied to the selected Customer.",
        });

      }

    },

    //tour methods
    startCustomersTour(){
    
      this.$tours.customersTour.start()

      this.tourIsActive = true

      if(this.selectedCustomer.id != ''){

        this.resetPreview();

      }

    },
    handleNextStep(currentStep){

      if(currentStep + 1 == 8){

        this.createCustomer();

      }

      if(currentStep + 1 == 9){

        document.querySelector(".tableActionBtns").style.position = "static"

        document.querySelector(".productSlideBox").style.overflowY  = "hidden"

      }

      if(currentStep >= 22){

        document.querySelector(".tableActionBtns").style.position = "sticky";

      }

      if(currentStep + 1 == 24){

        this.expand()

      }

      if(currentStep + 1 == 25){
                
        if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){
        
          this.expand()
        
        }
      
      }

    },
    handlePreviousStep(currentStep){

      if(currentStep == 8){

        this.resetPreview();

      }

      if(currentStep == 9){

        document.querySelector(".tableActionBtns").style.position = "sticky"

        document.querySelector(".productSlideBox").style.overflowY  = "auto"

      }

      if(currentStep <= 23){

        document.querySelector(".tableActionBtns").style.position = "static";

      }

      if(currentStep == 24){

        if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.expand();

        }

      }

      if(currentStep == 25){

        if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.expand();

        }

      }

    },
    handleTourStop(){
      
      if(this.selectedCustomer.id != ''){

        document.querySelector(".tableActionBtns").style.position = "sticky"

        document.querySelector(".productSlideBox").style.width = "50%";

        document.querySelector(".productSlideBox").style.overflowY  = "auto"

      }

      this.resetPreview()

      this.tourIsActive = false

    },
    handleTourFinish(){

      if(this.selectedCustomer.id != ''){

        document.querySelector(".tableActionBtns").style.position = "sticky"

        document.querySelector(".productSlideBox").style.width = "50%";

        document.querySelector(".productSlideBox").style.overflowY  = "auto"

      }

      this.resetPreview()

      this.tourIsActive = false

    },
    handleTourSkip(){

      if(this.selectedCustomer.id != ''){
        
        document.querySelector(".tableActionBtns").style.position = "sticky"

        document.querySelector(".productSlideBox").style.width = "50%";

        document.querySelector(".productSlideBox").style.overflowY  = "auto"

      }

      this.resetPreview()

      this.tourIsActive = false

    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },

    
    //fetch module data if needed
    fetchModuleData(){

      if(!this.customers || this.customers.length == 0){

        this.boxIsLoading = true;
        try{

          this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'});

        }catch(error){

          this.boxIsLoading = false;

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }

      }else{

        this.customerList = _.cloneDeep(this.customers);

        this.currentCustomerList = _.cloneDeep(this.customers);

      }

      if(!this.storeAllCountries || this.storeAllCountries.length == 0){

        try{

          this.$store.dispatch('countries_module/fetchAllCountries');

        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }

      }else{

        this.countriesList = this.storeAllCountries;

        this.countriesList.forEach((country)=>{

          this.allowedCountries.push(country.iso2);
          
        });

      }

    },

  },
  mounted(){

    //fetching module data
    this.fetchModuleData();

    this.$refs.customersTable.addEventListener('scroll', this.handleScrollEvent);

    window.addEventListener('keydown',this.customerKeyEvent)

    window.addEventListener('beforeunload', (event) => {

      let index = null;

      this.customerList.forEach((customer) => {

        if(customer.id == -1){

          index = this.customerList.indexOf(customer);

        }

      });

      if(this.selectedCustomer.id == -1 || index != null){

        event.returnValue = "Are you sure you want to leave? entered information will be lost";

      }

    });

    if(this.$route.query.search){
        
      this.$refs.module_search.searchedKeyword = this.$route.query.search

      if(this.$route.query.preview == 1){

        this.isMasterSearched = true;

      }

      this.$router.replace({'query': null});

      this.$refs.module_search.search();
      
    }

  },
  async beforeMount(){

  },
  beforeDestroy(){

    window.removeEventListener('keydown', this.customerKeyEvent);

  },
  beforeRouteLeave(to, from, next){

    let index = null;
    this.customerList.forEach((product) => {

      if(product.id == -1){

        index = this.customerList.indexOf(product);

      }

    });

    if(window.innerWidth <= 1024){

      document.body.classList.remove('activeIpad');
      document.body.classList.add('hideSideBar');

    }

    if((this.selectedCustomer.id || index != null) && to.fullPath != '/login'){

      MessageBox.confirm(
        'You may have unsaved changes, Proceeding will discard them',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Process',
          cancelButtonText: 'Stay',
        }
      ).then(()=>{

        this.resetPreview();

        next();

      }).catch(()=>{});

    }else{

      next();

    }

  },
};
</script>
<style scoped>
  .active-filter{
      border: 1px solid #1fc2c2;
      border-radius: 50px;
      padding: 0px 10px;
      text-align: center;
      color: #1fc2c2;
  }
  /* .swal2-container{
    z-index: 9999;
  } */

  /* States Css Start Here */
  .ctmDashboardCardRow{
    display: flex;
    align-items: center;
    gap: 15.4px;
    flex-wrap: wrap;
  }
  .statesWrapBlock{
    width: 15.83%;
  }
  .statesWrapBlock p{
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .statesWrapBlock p span{
    display: inline-flex;
    align-items: center;
    margin-left: 5.5px;
  }
  .statesWrapBlock p span svg{
    display: inline-block;
    width: 8.511px;
    height: 9.726px;
    margin-right: 2.5px;
  }
  .statesWrapBlock p span svg:nth-last-child(1){
    margin-right: 0px;
  }
  .ctmDashboardCardItem{
    width: 100%;
    padding: 12px 17px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ctmDashboardCardItem.lightBlueCart{
    background-color: #1EC2C2;
  }
  .ctmDashboardCardItem.lightYellowCart{
    background-color: #E0E847;
  }
  .ctmDashboardCardItem.lightRedCart{
    background-color: #FD4D5D;
  }
  .orderStatesTxtBlock{
    width: 144px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .statesTxtField{
    text-align: center;
  }
  .statesTxtField h4{
    font-size: 30px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField p{
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.weekDetailTxt{
    opacity: 0.9;
  }
  .statesTxtField.weekDetailTxt h4{
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.weekDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.monthlyDetailTxt{
    opacity: 0.8;
  }
  .statesTxtField.monthlyDetailTxt h4{
    font-size: 19px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.monthlyDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .cardTxtWrap h4{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
  }
  .cardTxtWrap h4 span{
    display: inline-block;
    margin-bottom: 0px !important;
    color: #15223D;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -5px;
    left: 3px
  }
  .cardTxtWrap p{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  .cardIconWrap{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #fff;
    padding: 12px;
  }
  .lightBlueCart .cardIconWrap{
    background: #2CCBCB;
  }
  .lightYellowCart .cardIconWrap{
    background: #D4DC38;
  }
  .lightYellowCart .cardTxtWrap h4{
    color: #15223D;
  }
  .lightYellowCart .cardTxtWrap p{
    color: #15223D;
  }
  .lightRedCart .cardIconWrap{
    background: #EF3F4F;
  }
  .cardIconWrap img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* States Css End Here */

  /* Transition Effects */
  .record-form-enter-active, 
  .record-form-leave-active {
    transition: all 0.3s ease-in-out;
  }

  /* Initial state when entering */
  .record-form-enter {
    opacity: 0;
    right: -100%;
    visibility: hidden;
  }

  /* Final state when leaving */
  .record-form-leave-to {
    opacity: 0;
    right: -100%;
    visibility: hidden;
  }


  /* Transition Effects */
  .modal-enter-active, 
  .modal-leave-active {
    transition: all 0.3s ease-in-out;
  }

  /* Initial state when entering */
  .modal-enter{
    opacity: 0;
    visibility: hidden;
  }

  /* Final state when leaving */
  .modal-leave-to {
    opacity: 0;
    visibility: hidden;
  }


</style>